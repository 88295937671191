.App {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    flex-direction: row;
}

.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
  display: flex;
  flex-direction: row;
}

.bottom-center {
    position:absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    bottom: 10px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    width: 350px;
    z-index: 999;
    color: white;
    font-family: "Neo-Navy";
}

.light-mode {
    color: black;
}

.center-text {
    text-align: center;
}

.center-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modal-header {
    justify-content: center !important;
    font-size: 2em;
    font-family: "2015 Cruiser";
    -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both !important;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both !important;
}

.modal-body {
    font-size: 1em;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.4s both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.4s both;
}

@media screen and (max-width: 600px) {
    .modal-body {
        font-size: 0.75em;
    }

    .sound-toggle {
      display: none;
      opacity: 0;
  }
}

.modal-content {
    background: linear-gradient(to bottom, rgba(38,26,127,1) 0%, rgba(87,34,161,1) 50%, rgba(166,55,212,1) 100%);
    color: white;
    border: 1px solid white !important;
    box-shadow: 0px 0px 10px 5px rgba(183, 197, 236, 1);
}

.light-mode .modal-content {
    color:rgba(112, 6, 99, 1) !important;
    background: linear-gradient(to bottom, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 100%) !important;
    border: 1px solid rgba(112, 6, 99, 1) !important;
    box-shadow: 0px 0px 5px 3px rgba(184, 88, 173, 1) !important;

}

.modal-footer {
    justify-content: center !important;
}

.hover-block {
    transition: background-color 200ms ease-out;
    border-radius: 5px;
    padding: 10px;
    color: white;
    border: 1px solid transparent;
}

.hover-block:hover {
    transition: background-color 200ms ease-out;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    border: 1px solid white;
}

.light-mode > .hover-block {
    color: rgba(112, 6, 99, 1);
}

.light-mode > .hover-block:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(112, 6, 99, 1);
    border: 1px solid rgba(184, 88, 173, 1);
}

.sound-toggle {
    position: relative;
    top: -4px;
    margin-right: 5px;
    font-size: 0.6em;
}

#tsparticles {
    position: absolute;
    width: 100vw;
    height: 120vh;
    background: #4568dc;
    background: linear-gradient(to bottom, rgba(38,26,127,1) 0%, rgba(87,34,161,1) 50%, rgba(166,55,212,1) 100%);
}

.light-mode > #tsparticles {
    background: linear-gradient(to bottom, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 100%);
}

.modal-close-button {
    font-family: "Neo-Navy";
    background-color: transparent;
    border: 1px solid white;
    transition: all 200ms ease-in-out;
    color: white;
    padding: 15px 40px;
    width: 100%;
    border-radius: 10px;
}

.modal-close-button:hover {
    color:rgba(112, 6, 99, 1);
    background: linear-gradient(to right, rgba(179,220,237,0.2) 0%, rgba(255, 255, 255, 1.0) 100%);
}

.lower-button-text {
  font-size: 1em;
  font-family: 'Unscreen';
}

@-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .chain-logo {
    width: 30px;
  }