.block-selection {
  max-width: 380px;
  padding: 0px 20px;
  margin: -70px auto 10px auto;
  font: 700 18px/1.2 "Unscreen", Arial, sans-serif;

  .logo {
    display: block;
    z-index: 100;
    position: relative;

    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .box-area-light {
    border: 3px solid rgb(184, 88, 173) !important;
    box-shadow: 0px 0px 5px 3px rgba(184, 88, 173, 1) !important;
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.6)) !important;

    &:before {
      border: 3px solid rgba(150, 9, 133, 0.75) !important;
    }
  }

  .box-area {
    // border: 3px solid #873091;
    border: 3px solid rgba(183, 197, 236, 1);
    position: relative;
    margin: -7px 0 0;
    padding: 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgba(183, 197, 236, 1);
    // background-color: rgba(255, 255, 255, 0.4);
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      border: 3px solid rgba(243, 176, 251, 1);
      border-radius: 6px;
      pointer-events: none;
    }
  }

  .bars-holder {
    position: relative;
    padding: 20px 15px 0;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 30px;
      content: "";
      // border: 3px solid rgba(183, 197, 236, 1);
      // border: 3px solid #9e42a7;
      border-radius: 10px;
      pointer-events: none;
    }

    &:after {
      left: 6px;
      right: 6px;
      top: 6px;
      bottom: 36px;
    }
  }

  .coin-holder {
    display: block;
    width: 180px;
    margin: 0px auto 20px;
    border-radius: 100%;
    -webkit-perspective: 900000px;
    perspective: 900000px;

    .img-wrapper {
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      margin: auto;
    
      &::before {
        background: linear-gradient(
          to right,
          fade_out(#fff, 1) 0%,
          fade_out(#fff, 0.7) 100%
        );
        content: "";
        display: block;
        height: 100%;
        left: -75%;
        position: absolute;
        // top: 0;
        transform: skewX(-25deg);
        width: 50%;
        z-index: 2;
      }
    
      &:hover,
      &:focus {
        &::before {
          animation: shine 0.85s;
        }
      }
    
      @keyframes shine {
        100% {
          left: 125%;
        }
      }
      img {
        // width: 100%;
        height: auto;
        top: 0px;
        margin-top: -38px;
        margin-left: -34px;
        // margin-top: -30px;
        // vertical-align: top;
        transition: transform 2s ease-out;
        width: 190px;
      }
    }

    img {
      width: 100%;
      height: auto;
      margin-top: -30px;
      // vertical-align: top;
      transition: transform 2s ease-out;
      width: 180px;
    }

  }

  .coin-holder > .img-spin {
    animation: spin-animation 10s infinite linear;
    -webkit-animation: spin-animation 10s infinite linear;
  }

  @supports (-webkit-touch-callout: none) {
    .coin-holder {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  .no-padding {
    padding: 0px !important;
  }

  .list-items,
  .list-more-options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    color: #f967fd;

    li {
      padding: 0 4px;
      margin: 0 0 8px;
    }
  }

  .list-items {
    flex-wrap: nowrap;
    padding-bottom: 10px;
  }

  .w-33 {
    width: 33.333%;
  }

  .w-50 {
    width: 50%;
  }

  .w-100 {
    width: 100%;
  }

  .bars {
    position: absolute;
    left: 100%;
    top: -6px;
    margin: 0 0 0 2px;
    width: 10px;

    &.bottom {
      top: auto;
      bottom: 34px;
      left: auto;
      right: 100%;
      margin: 0 2px 0 0;
    }

    .bar {
      transform: rotate(-45deg);
      height: 2px;
      background: #4eafcf;
      display: block;
      margin: 6px 0 0;
    }
  }

  .heading {
    display: block;
    margin: 0 0 15px;
    text-align: center;
  }
}

.dark-mode-text {
  color: white;
}

.fake-radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.2em;
  font-family: "Unscreen";

  &:hover {
    .btn-radio {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(112, 6, 99, 1);
      &:before {
        opacity: 0;
        visibility: hidden;
      }

      .text-box {
        color: #fff;
        background: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #fff;
      }
      .text-box-light {
        color: rgba(112, 6, 99, 1);
        background: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(112, 6, 99, 1);
      }
    }
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked {
      + .btn-radio {
        border: 1px solid white;
        background: rgba(112, 6, 99, 1);
        transition: background-color 200ms ease-in-out;
        &:before {
          opacity: 0;
          visibility: hidden;
        }

        .text-box {
          color: #fff;
          background: none;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }

  .btn-radio {
    display: block;
    padding: 5px 8px;
    // background: linear-gradient(
    //   45deg,
    //   rgba(112, 6, 99, 1) 0%,
    //   rgba(148, 16, 132, 1) 100%
    // );
    // background: rgba(112, 6, 99, 1);
    border: 1px solid transparent;
    position: relative;
    z-index: 2;
    transition: all 0.4s ease;
    border-radius: 10px;
    // padding: 10px 10px;

    &:before {
      transition: all 0.4s ease;
      z-index: -1;
      position: absolute;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      content: "";
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(112, 6, 99, 1);
      border-radius: 10px;
    }
  }

  .text-box {
    transition: all 0.4s ease;
    text-align: center;
    display: block;
    background: -webkit-linear-gradient(
      rgba(112, 6, 99, 1),
      rgba(148, 16, 132, 1)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: rgba(112, 6, 99, 1);
  }

  .price {
    display: block;
    font-family: "2015 Cruiser";
    // font-size: 1.5em;
  }
}

.result-box {
  padding: 0 0 15px;
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  text-align: center;

  .title {
    display: block;
  }

  .price {
    display: block;
    padding: 5px 0 0;
    color: #12d30f;

    &.lost {
      color: #f00;
    }

    &.loading {
      color: #888
    }
  }
}

.hexa-button {
  width: 230px;
  height: 66px;
  background: #63326b;
  color: #fff;
  position: relative;
  border-top: 3px solid #63326b;
  border-bottom: 3px solid #63326b;
  margin: 5px auto 0;
  display: block;
  box-sizing: border-box;
  z-index: 1;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  filter: grayscale(40%);
  text-decoration: none;

  &:hover {
    // opacity: 0.85;
    transform: scale(1.05);
    filter: grayscale(0%);
    .text {
      color: gold !important;
      transition: color 200ms ease-in-out;
    }
    
  }
}

.hexa-inner {
  height: 66px;
  position: relative;
  margin: -3px 0;
}

.hexa-inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 4px;
  background: #63326b;
  transform: skew(-18deg, 0deg);
}

.hexa-inner:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 4px;
  background: #63326b;
  transform: skew(-162deg, 0deg);
}

.left-arrow {
  margin-left: -7px;
  float: left;
}

.right-arrow {
  transform: rotate(180deg);
  float: right;
  margin-right: -7px;
}

.hexa-button .text {
  font-size: 32px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #fff;
  transition: color 200ms ease-in-out;
  text-align: center;
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.btn-small {
    font-size: 20px;
  }
}

.hexa-button-inner1 {
  width: auto;
  height: 60px;
  background: #a186a6;
  color: #fff;
  position: relative;
  border-top: 3px solid #a186a6;
  border-bottom: 3px solid #a186a6;
  display: block;
  box-sizing: border-box;
  margin: 0 3px;
}

.hexa-inner1 {
  height: 60px;
  position: relative;
  margin-top: -3px;
}

.hexa-inner1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 4px;
  background: #a186a6;
  transform: skew(-18deg, 0deg);
}

.hexa-inner1:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 4px;
  background: #a186a6;
  transform: skew(-162deg, 0deg);
}

.hexa-button-inner2 {
  width: auto;
  height: 54px;
  background: #dd8acf;
  color: #fff;
  position: relative;
  border-top: 3px solid #dd8acf;
  border-bottom: 3px solid #dd8acf;
  display: block;
  box-sizing: border-box;
  margin: 0 3px;
  z-index: 1;
}

.hexa-inner2 {
  height: 54px;
  position: relative;
  margin-top: -3px;
}

.hexa-inner2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 4px;
  background: #dd8acf;
  transform: skew(-18deg, 0deg);
}

.hexa-inner2:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 4px;
  background: #dd8acf;
  transform: skew(-162deg, 0deg);
}

.hexa-button-inner3 {
  width: auto;
  height: 24px;
  background: #c211bc;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 3px solid #c211bc;
  border-bottom: 3px solid #c211bc;
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.hexa-button-inner3:before,
.hexa-button-inner3:after {
  content: "";
  position: absolute;
  top: -25px;
  left: -6px;
  background: #dd8acf;
  width: 10px;
  height: 25px;
  transform: rotate(15deg);
  z-index: -1;
}

.hexa-button-inner3:after {
  left: auto;
  right: -6px;
  transform: rotate(-15deg);
}

.hexa-inner3 {
  height: 24px;
  position: relative;
  margin-top: -3px;
}

.hexa-inner3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 3px;
  height: 100%;
  width: 4px;
  background: #c211bc;
  transform: skew(-18deg, 0deg);
}

.hexa-inner3:after {
  content: "";
  position: absolute;
  top: 0;
  left: 3px;
  height: 100%;
  width: 4px;
  background: #c211bc;
  transform: skew(-162deg, 0deg);
}

.hexa-inner3.left-arrow:before,
.hexa-inner3.right-arrow:after {
  width: 6px;
  height: 10px;
  transform: none;
}

.hexa-inner3.right-arrow:after {
  top: 14px;
}

.flippy-front,.flippy-back {
  box-shadow: none !important;
}

.scrollable {
  overflow: scroll;
  max-height: 60vh;
}

.hide {
  display: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  margin: 0 !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.table-headers {
  font-size: 1.3em;
  font-weight: 600;
}

@keyframes spin-animation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

@-webkit-keyframes spin-animation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.pixel-font {
  font-family: "Unscreen";
}

.cruiser-font {
  font-family: "2015 Cruiser";
}

@media screen and (max-width: 600px) {
  .bars-holder {
    padding: 0px 5px 0 !important;
  }

  .list-items {
    padding-bottom: 5px !important;
  }

  .hide-mobile {
    display: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    margin: 0 !important;
  }

  .text-box {
    font-size: 0.8em;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.w-100 {
  width: 200px !important;
  margin: 0 !important;
}

.enter-hash-box {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
  color: white !important;
  border: 1px solid white !important;
}

.switcher-panel {
  border-radius: 10px;
  border: 2px solid white;
  background: (rgba, 255, 255, 255, 0.75);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.switcher-logo {
  padding: 20px;
  width: 20% !important;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
  margin: 5px;
}

.switcher-logo:hover {
  transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border: 1px solid white;
  border-radius: 10px;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}